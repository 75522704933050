<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterUsers"
      @clear="clearFilter"
      currentComponent="users-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td class="text-center">
          <ImageAvatar
            :img_src="item.image_profile"
            defaultIcon="fas fa-user"
          />
        </td>
        <td>{{ item.name }}</td>
        <td class="text-center">
          <StatusChip :currentStatus="item.status" />
        </td>
        <td class="text-center">{{ item.email }}</td>
        <td class="text-center">{{ item.contact }}</td>
        <td class="text-center">{{ item.profile_type }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    StatusChip,
    FilterDrawer,
    ImageAvatar,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    dataTable: {
      title: 'Usuários',
      new: 'users-new',
      edit: 'users-edit',
      context: 'users-context',
      massAction: 'users-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'image_profile',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'contact', align: 'center' },
        { text: 'Perfil', value: 'profile_type', align: 'center' },
      ],
    },
  }),
  methods: {
    getUsers() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/users', {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterUsers(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/user', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getUsers()
    },
  },
  mounted() {
    this.getUsers()

    contextMenu.$once('action', (type) => {
      if (type === 'users') {
        this.getUsers()
      }
    })
    massAction.$once('action', () => {
      this.getUsers()
    })
  },
}
</script>
